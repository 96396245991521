@include component('service') {
	background-color:#3d811c;
	background-image:url('../img/bg-texture.jpg');
	background-position:center top;
	background-size:100% auto;
	background-repeat:no-repeat;
	padding:30px 30px 0;
	@include element('title') {
		font-size:50px;
		text-transform:uppercase;
		margin-bottom:50px;
		color:#fff;
		span{
			font-weight:300;
			margin-right:10px;
			font-family:$font_base03;
		}
		strong{
			font-weight:700;
			font-family:$font_base02;
		}
		@include max-screen(768px) {
			font-size:30px;
			line-height:34px;
			text-align:center;
			span,strong{
				// display:block;
				margin-bottom:15px;
			}
		}
	}
	@include element('list') {
		text-align:center;
		font-size:0;
	}
	@include element('item') {
		padding:25px;
		width:250px;
		border-left:solid 1px #fff;
		position:relative;
		color:#fff;
		margin-top:75px;
		display: inline-block;
		min-height:220px;
		vertical-align:top;
		text-align:left;
		@include min-screen(1201px) {
			width:225px;
		}
		@include max-screen(1200px) {
			width:180px;
			padding:15px;
		}
		@include max-screen(992px) {
			width:50%;
			min-height:150px;
			margin-bottom:30px;
			padding:25px;
		}
		@include max-screen(480px) {
			width:100%;
			min-height:inherit;
		}
	}
	@include element('icon') {
		position:absolute;
		top:-75px;
		left:0;
		img{
			height:75px;
			width:auto;
		}
	}
	@include element('subtitle') {
		font-size:26px;
		text-transform:uppercase;
		margin-bottom:20px;
		display:block;
		font-family: $font_base03;
	}
	@include element('text') {
		font-size:18px;
		line-height: 22px;
		font-weight:700;
		margin-bottom:0;
		font-family: $font_base02;
	}
	@include max-screen(992px) {
		background-size:auto 100%;
	}
	@include max-screen(768px) {
		padding:15px;
	}
}

@include object('list') {
	font-size:30px;
	font-weight:700;
	color:#FFF;
	text-transform:uppercase;
	text-align:center;
	margin:50px 0;
	li{
		display:inline-block;
		padding-left:15px;
		position: relative;
		font-family:$font_base02;
		font-weight:700;
		&:before{
			content:'';
			display:inline-block;
			// position:absolute;
			// left:0;
			// top:0;
			// bottom:0;
			margin-top:-4px;
			margin-right:6px;
			line-height:20px;
			vertical-align:middle;
			width:6px;
			height:6px;
			background-color:#FFF;
			border-radius:6px;

		}
		@include max-screen(768px) {
			padding-left:0;
		}
	}
	@include max-screen(768px) {
		margin:0;
	}
}
