@include component('contact') {
	text-align:center;
	margin-top:50px;
	@include element('data') {
		display:inline-block;
		text-align:left;
		color:#fff;
		padding-left:300px;
		position:relative;
		padding-right: 200px;
		padding-bottom:100px;
		background-image:url('../img/hormigon.png');
		background-repeat:no-repeat;
		background-position:right bottom 20px;
		background-size:auto 200px;
		@include max-screen(992px) {
			padding:30px 0;
			text-align: center;
			background:transparent;
		}
	}
	@include element('title') {
		font-size:26px;
		font-family:$font_base02;
		font-weight:700;
		text-transform:uppercase;
	}
	@include element('phone') {
		color:#fff;
		font-size:40px;
		font-family:$font_base02;
		font-weight:700;
		display:block;
		margin-bottom:10px;
		&:hover{
			text-decoration:none;
			color:#245003;
		}
	}
	@include element('swp') {
		color:#fff;
		font-size:40px;
		font-family:$font_base02;
		font-weight:700;
		display:block;
		padding-left:50px;
		background-image:url('../img/wsp.png');
		background-size:auto 100%;
		background-repeat:no-repeat;
		background-position:left center;
	}
	@include element('mail') {
		color:#fff;
		font-size:30px;
		font-family:$font_base03;
		font-weight:300;
		display:block;
		margin-bottom:15px;
		&:hover{
			text-decoration:none;
			color:#245003;
		}
	}
	@include element('casco') {
		position:absolute;
		left:-50px;
		bottom:-30px;
		width:330px;
		@include max-screen(992px) {
			display:none;
		}
	}
	@include max-screen(992px) {
		text-align: center;
	}
	@include max-screen(768px) {
		margin-top: 0;
	}
}
