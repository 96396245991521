@include component('header') {
	background-color:$color_primary;
	padding:15px;
	position:fixed;
	top:0;
	left:0;
	width:100%;
	z-index:998;
}
.btn{
	&.btn--burder{
		display:block;
		width:30px;
		height:21px;
		margin-top:6px;
		background-image:url('../img/burger.png');
	}
	&.btn--icon{
		display:inline-block;
		width:30px;
		height:30px;
		margin:0 3px;
		&:last-child{
			margin-right:0;
		}
	}
	&.btn--facebook{
		background-image:url('../img/facebook.png');
	}
	&.btn--youtube{
		background-image:url('../img/youtube.png');
	}
	&.btn--instagram{
		background-image:url('../img/instagram.png');
	}
	&.btn--twitter{
		background-image:url('../img/twitter.png');
	}
	background-position:center top;
	background-size:100% auto;
	background-repeat:no-repeat;
	overflow:hidden;
	&:hover{
		background-position:center bottom;
	}
}
a.btn{transition-duration: 0s}
