@include object('form') {
	position:relative;
	@include element('field') {
		margin-bottom:10px;
		border:solid 2px $color_primary;
		display:block;
		width:100%;
		text-align:left;
		font-size:0;
		&.no-border{
			border:none;
		}
		@include modifier('area') {
			.o-form__label{
				display:block;
				width:100%;
				padding-bottom:0;
			}
			.o-form__area{
				display:block;
				width:100%;
			}
		}
		&:last-child{
			margin-bottom:0;
		}
	}
	@include element('label') {
		display:inline-block;
		font-family:$font_base;
		padding: 10px;
		vertical-align:middle;
		text-align:left;
		color:$color_primary;
		font-size:20px;
		width:15%;
		@include max-screen(768px) {
			width:30%;
		}
		@include max-screen(480px) {
			padding:5px;
		}
	}
	@include element('input') {
		font-size:20px;
		font-family:$font_base;
		padding: 10px;
		display:inline-block;
		vertical-align:middle;
		text-align:left;
		width:85%;
		border:none;
		&:focus{
			outline:none;
		}
		@include max-screen(768px) {
			width:70%;
		}
		@include max-screen(480px) {
			padding:5px;
		}
	}
	@include element('area') {
		width:85%;
		padding:10px;
		display:inline-block;
		vertical-align:middle;
		min-height:100px;
		font-size:16px;
		line-height:20px;
		border:none;
		&:focus{
			outline:none;
		}
		@include max-screen(768px) {
			min-height:60px;
		}
	}
	@include element('btn') {
		text-transform:uppercase;
		font-weight:900;
		border:none;
		background-color:$color_primary;
		color:#fff;
		line-height:100%;
		padding: 15px;
		font-size:18px;
		&:focus{
			outline: none;
		}
		&:hover{
			background-color:#5A7D2D;
		}
	}
	&.emailjs-sending{
		&:before{
			content:'Enviado datos';
			position:absolute;
			bottom:15px;
			left:0;
			font-size:20px;
			color:$color_primary;
			background-image:url('../img/loader.gif');
			background-repeat:no-repeat;
			background-position:left center;
			padding-left:25px;
		}
	}
}
