@font-face {
	font-family:'CondensedBold';
	src: url('../fonts/CondensedBold.eot');
	src: url('../fonts/CondensedBold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/CondensedBold.woff2') format('woff2'),
		url('../fonts/CondensedBold.woff') format('woff'),
		url('../fonts/CondensedBold.svg#o CondensedBold') format('svg');
	font-weight: 700;
	font-style: normal;
	font-stretch: condensed;
	unicode-range: U+0020-00FE;
}

@font-face {
	font-family:'CondensedLight';
	src: url('../fonts/CondensedLight.eot');
	src: url('../fonts/CondensedLight.eot?#iefix') format('embedded-opentype'),
		url('../fonts/CondensedLight.woff2') format('woff2'),
		url('../fonts/CondensedLight.woff') format('woff'),
		url('../fonts/CondensedLight.svg#o CondensedLight') format('svg');
	font-weight: 300;
	font-style: normal;
	font-stretch: condensed;
	unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'DINProCond';
    src: url('../fonts/DINProCondensedRegular.eot');
	src: url('../fonts/DINProCondensedRegular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/DINProCondensedRegular.woff2') format('woff2'),
		url('../fonts/DINProCondensedRegular.woff') format('woff'),
		url('../fonts/DINProCondensedRegular.svg#DIN Pro Condensed Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: condensed;
    unicode-range: U+0020-00FE;
}


@font-face {
    font-family:'ghotic';
    src: url('../fonts/Regular.eot');
	src: url('../fonts/Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Regular.woff2') format('woff2'),
		url('../fonts/Regular.woff') format('woff'),
		url('../fonts/Regular.svg#T Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: semi-expanded;
    unicode-range: U+0020-02DC;
}
