@include component('display') {
	height:calc(100vh - 63px);
	background-image:url('../img/bg-display.jpg');
	background-repeat:no-repeat;
	background-attachment: fixed;
	background-position:center;
	background-size:cover;
	width:100%;
	display:block;
	position:relative;
	@include element('brand') {
		display:block;
		margin-bottom:100px;
		img{
			@include max-screen(768px) {
				height:auto;
				width:90%;
				max-width:450px;
			}
		}
	}
	@include element('btn') {
		background-color:#fff;
		border: 6px solid $color_primary;
		padding:15px;
		display:inline-block;
		line-height:100%;
		color:$color_primary;
		text-transform:uppercase;
		font-size:34px;
		span{
			font-weight:300;
			font-family:$font_base03;
			margin-right:4px;
		}
		strong{
			font-weight:700;
			font-family:$font_base02;
		}
		&:hover{
			background-color:$color_primary;
			text-decoration:none;
			color:$white;
		}
	}
}
