@include component('footer') {
	background-color:#245003;
	padding:15px;
	font-family:$font_base03;
	text-align:center;
	color: #fff;
	text-transform:uppercase;
	font-size:22px;
	p{
		margin-bottom:0;
	}
}
