@include object('btn') {
	text-transform:uppercase;
	font-weight:900;
	border:none;
	background-color:$color_primary;
	color:#fff;
	line-height:100%;
	padding: 15px;
	font-size:18px;
	&:focus{
		outline: none;
	}
	&:hover{
		background-color:#5A7D2D;
	}
}
