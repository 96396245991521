@include component('modal') {
	z-index:1000;
	position: fixed;
	top:0;
	left:0;
	width:100%;
	height:100vh;
	text-align:center;
	display:none;
	@include element('header') {
		text-align:right;
	}
	@include element('title') {
		background-color:#fff;
		font-family:$font_base02;
		border: 6px solid $color_primary;
		padding:10px;
		display:block;
		margin:10px 0;
		text-align:center;
		line-height:100%;
		color:$color_primary;
		text-transform:uppercase;
		font-size:30px;
		strong{
			font-weight:700;
		}
		@include max-screen(480px) {
			font-size:16px;
		}
	}
	@include element('close') {
		width:22px;
		height:25px;
		display:inline-block;
		background-image:url('../img/close.png');
		background-repeat:no-repeat;
		background-position:center top;
		transition-duration: 0s;
		&:hover{
			background-position:center bottom;
		}
	}
	@include element('container') {
		width:50%;
		display:inline-block;
		max-width:780px;
		height:auto;
		background-color:#fff;
		padding:20px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right:0;
		@include max-screen(768px) {
			width:80%;
			padding:15px;
		}
		@include max-screen(480px) {
			width:90%;
		}
	}
}
