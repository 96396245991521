@include component('about') {
	@include element('display') {
		background-image:url('../img/display-about.jpg');
		background-position:left top;
		background-repeat:no-repeat;
		background-size:cover;
		min-height:400px;
		width:100%;
		height:100%;
		@include max-screen(992px) {
			min-height:340px;
		}
		@include max-screen(768px) {
			height:200px;
			min-height:inherit;
			// height:200px;
		}
	}
	@include element('body') {
		background-color:#8C9A0A;
		color:#fff;
		padding:30px;
		text-transform:uppercase;
		line-height:26px;
		font-size:20px;
		min-height:400px;
		height:100%;
		p{
			margin:0;
		}
		@include min-screen(1201px) {
			font-size:22px;
			line-height:28px;
		}
		@include max-screen(992px) {
			font-size:19px;
			line-height:22px;
			min-height:340px;
			padding:15px;
		}
		@include max-screen(768px) {
			min-height:inherit;
			// font-size:22px;
			// line-height:28px;
		}
	}
}
