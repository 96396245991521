@include component('nav') {
	background-color:$color_primary;
	position:absolute;
	top:10px;
	left:0;
	transform:translateX(-120%);
	transition: all .3s ease-in-out;
	z-index:999;
	@include state('active') {
		transform:translateX(0%);
	}
	@include element('items') {
		display:block;
		position:relative;
	}
	@include element('item') {
		display:block;
	}
	@include element('link') {
	color:$white;
	display:block;
	text-transform: uppercase;
	padding:8px 10px;
	font-size:22px;
	font-family:$font_base02;
	line-height:100%;
		&:hover{
			text-decoration:none;
			background-color:#407B12;
			color:#fff;
		}
	}
	@include element('close') {
		span{
			width:15px;
			height:17px;
			background-image:url('../img/close-menu.png');
			background-position: center top;
			background-repeat:no-repeat;
			display:block;
		}
		position:absolute;
		padding:10px;
		background-color:#113701;
		right:-35px;
		top:0;
		display:block;

	}
}
